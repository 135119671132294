.custom-select {
  height: auto !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
}
.swal2-input {
  width: 300px !important;
}
.swal2-input,
.custom-select {
  width: 300px !important;
}
