.h1-accordion {
  text-align: center;
  margin: 2rem 0 4rem 0;
}
.accordion {
  max-width: 110%;
  margin: 1rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.accordion-title:hover {
  background-color: #0d6efd;
  color: #fff;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}
.accordion-content {
}
